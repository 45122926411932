/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import * as yup from 'yup';
import moment from 'moment';
import {
	DrawerPriceType,
	Market,
	OrderType,
	Payment,
	PriceIncrementType,
	PriceType,
	QuoteType,
	portsIncos,
	Inspection,
	CargoType,
	Environment,
	NameVisibility,
	Role,
	isBasisPrice,
	MaxBasisPrice,
	PriceTooBigKey,
	PriceTooSmallKey,
	PaperInstruments,
} from 'src/constants/contract';
import { FILES_PREFIX } from 'src/constants/files';
import { specialities } from 'src/constants/product';
import { Dateformat } from 'src/_helpers/date';
import { dateFromUnix } from 'src/_helpers/yup';
import { isNil } from 'src/_helpers';
import { getDefaultDateTimeTzValue, timezoneDateToIsoString } from '../../DateTimeTzPicker';
import { getTimeOptionMinutes, mapTimeRangeOptionToApi } from 'src/_helpers/timeRange';
import { mapValidity } from '../paper/model';

export const MAXIMUM_TERMINAL_NAME_LENGTH = 30;
export const MINIMAL_LOADING_DISCHARGE_RATE = 0;
export const MAXIMUM_LOADING_DISCHARGE_RATE = 50000;

export const defaultValues = {
	environment: Environment.Exchange,
	nameVisibility: NameVisibility.Hidden,
	role: Role.Principal,
	isCustomGrade: false,
	customSpecs: {},
	cargoType: CargoType.Bulk,
	validity: getDefaultDateTimeTzValue(),
	orderType: QuoteType.Indicative,
	documents: [],
	documentNames: [],
	allDocumentsUploaded: true,
	priceType: PriceType.Flat,
	futuresMonth: null,
	contractNumber: '',
	ports: [{ port: '', volume: null, terminal: '', rate: null }],
};

export const createPhysicalOrderSchema = yup.object({
	type: yup.mixed().oneOf(Object.values(OrderType)).required(),
	volume: yup.number().required().min(1),
	inco: yup.string().required(),
	harvest: yup.number().nullable(),
	speciality: yup
		.mixed()
		.oneOf([null, undefined, ...specialities])
		.nullable(),
	port: yup.string().required(),
	portNames: yup.object(),
	ports: yup.array(
		yup.object({
			name: yup.string().required(),
			volume: yup.number().when('$volume', volume => {
				return volume ? yup.number().max(volume).nullable() : yup.number().nullable();
			}),
			terminal: yup.string(),
			rate: yup
				.number()
				.min(MINIMAL_LOADING_DISCHARGE_RATE)
				.max(MAXIMUM_LOADING_DISCHARGE_RATE)
				.nullable(),
		})
	),
	productAndType: yup.string().required(),
	date: yup
		.object({
			startDate: yup.string().required(),
			endDate: yup.string().required(),
			format: yup.mixed().oneOf(Object.values(Dateformat)),
		})
		.required(),
	grade: yup.string().required(),
	specs: yup.array(),
	isCustomGrade: yup.boolean().nullable(),
	customSpecs: yup
		.mixed()
		.when('isCustomGrade', {
			is: true,
			then: yup
				.object()
				.test('valid-specs', 'At least one of specs must be completed', specs => {
					return Object.values(specs).some(value => !!value);
				}),
		})
		.nullable(),
	origin: yup.array(),
	orderType: yup.mixed().oneOf(Object.values(QuoteType)).required(),
	priceType: yup.mixed().oneOf(Object.values(DrawerPriceType)).required(),
	futuresMonth: yup.mixed().when('priceType', {
		is: value => isBasisPrice(value),
		then: yup.date().transform(dateFromUnix).required(),
	}),
	price: yup.mixed().when(['priceType', 'currencyUnit'], (priceType, unit) => {
		if (isBasisPrice(priceType)) {
			return yup
				.number()
				.test({
					name: PriceTooBigKey,
					params: { unit },
					message: unit,
					test: price => {
						if (price >= MaxBasisPrice) {
							return false;
						}
						return true;
					},
				})
				.test({
					name: PriceTooSmallKey,
					params: { unit },
					message: unit,
					test: price => {
						if (price <= -1 * MaxBasisPrice) {
							return false;
						}
						return true;
					},
				})
				.required();
		} else if (priceType !== DrawerPriceType.NoPrice) {
			return yup
				.number()
				.test('not-zero', 'Flat price cannot be zero', price => {
					// eslint-disable-next-line eqeqeq
					return price != 0;
				})
				.required();
		} else {
			return yup.number().nullable();
		}
	}),
	currencyUnit: yup.string(),
	payment: yup
		.mixed()
		.oneOf([...Object.values(Payment), null])
		.nullable(),
	priceIncrementType: yup
		.mixed()
		.oneOf([...Object.values(PriceIncrementType), null])
		.nullable(),
	priceIncrementBasisShipment: yup.mixed().when('priceIncrementType', {
		is: value => [PriceIncrementType.HalfMonthly, PriceIncrementType.Monthly].includes(value),
		then: yup
			.object({
				startDate: yup.string().required(),
				endDate: yup.string().required(),
				format: yup.mixed().oneOf(Object.values(Dateformat)),
			})
			.required(),
	}),
	priceIncrement: yup.mixed().when('priceIncrementType', {
		is: value => [PriceIncrementType.HalfMonthly, PriceIncrementType.Monthly].includes(value),
		then: yup.number().required(),
	}),
	cargoType: yup.string().required(),
	inspection: yup
		.mixed()
		.oneOf([null, undefined, '', ...Object.values(Inspection)])
		.nullable(),
	environment: yup.mixed().oneOf(Object.values(Environment)).required(),
	nameVisibility: yup.mixed().oneOf(Object.values(NameVisibility)).required(),
	role: yup.mixed().oneOf(Object.values(Role)).required(),
	performanceBond: yup.number().nullable(),
	contractTerms: yup.string().nullable(),
	contractNumber: yup.string().nullable(),
	file_contract_id: yup.string().nullable(),
	comment: yup.string().nullable(),
	counterparties: yup.mixed().when('environment', {
		is: Environment.OTC,
		then: yup.array().required(),
	}),
	documents: yup.array().of(yup.string()),
	validity: yup
		.object({
			time: yup.string(),
			localDate: yup.date().when('time', time => {
				if (!!time) {
					return yup.date();
				}
				return yup.date().test('min', val => yup.date().min(new Date()).isValidSync(val));
			}),
			tz: yup.string(),
		})
		.required(),
});

export const createPhysicalCounterSchema = createPhysicalOrderSchema.shape({
	// counterparties are not required in counter schema
	counterparties: yup.mixed(),
});

export const mapPortsToApi = data => {
	return data.ports.map(port => {
		const result = {
			port_id: port.name,
			volume: port.volume ? +port.volume : undefined,
			terminal: port.terminal,
		};
		if (port.rate) {
			result.rate = +port.rate;
		}
		return result;
	});
};

export const filterEmptyFields = data => {
	return Object.entries(data).reduce((acc, [key, value]) => {
		if (value !== null && value !== undefined && value !== '') {
			acc[key] = value;
		}
		return acc;
	}, {});
};

export const mapDataToApi = data => {
	const isDischargeInco = portsIncos.dischargePorts.includes(data.inco);
	const isLoadingInco = portsIncos.loadingPorts.includes(data.inco);

	let ports_loading;
	let ports_discharging;

	if (isDischargeInco) {
		ports_loading = [];
		ports_discharging = mapPortsToApi(data);
	}

	if (isLoadingInco) {
		ports_loading = mapPortsToApi(data);
		ports_discharging = [];
	}

	const isPriceIncrementDefined = [
		PriceIncrementType.HalfMonthly,
		PriceIncrementType.Monthly,
	].includes(data.priceIncrementType);

	const hasPrice = data.priceType !== DrawerPriceType.NoPrice;

	const isBasisPriceType = isBasisPrice(data.priceType);

	const [product_id, type_id] = data.productAndType.split('|');

	const recipients = data.fullContactsList
		?.map(user => user._key)
		.filter((id, index, list) => list.indexOf(id) === index);

	return filterEmptyFields({
		order_type: data.type,
		market: Market.Physical,
		product_id,
		type_id,
		harvest_year: data.harvest,
		origin_country_ids: data.origin?.map(origin =>
			typeof origin === 'string' ? origin : origin.id
		),
		instrument: PaperInstruments.Outright,
		speciality: data.speciality,
		inco_id: data.inco,
		volume: data.volume,
		delivery_date_from: data.date.startDate,
		delivery_date_to: data.date.endDate,
		delivery_mode: data.date.format,
		ports_loading,
		ports_discharging,
		grade_id: data.grade,
		has_price: hasPrice,
		price: hasPrice ? data.price : null,
		is_indicative: data.orderType === QuoteType.Indicative,
		price_type: hasPrice ? (isBasisPriceType ? PriceType.Basis : PriceType.Flat) : null,
		...(isBasisPriceType &&
			hasPrice && {
				futures_contract: data.priceType,
				futures_contract_date: data.futuresMonth,
			}),
		payment: data.payment,
		price_increment_type: isPriceIncrementDefined ? data.priceIncrementType : undefined,
		price_increment_basis_shipment_from: isPriceIncrementDefined
			? data.priceIncrementBasisShipment?.startDate
			: undefined,
		price_increment_basis_shipment_to: isPriceIncrementDefined
			? data.priceIncrementBasisShipment?.endDate
			: undefined,
		price_increment_basis_shipment_mode: isPriceIncrementDefined
			? data.priceIncrementBasisShipment?.format
			: undefined,
		price_increment: isPriceIncrementDefined ? data.priceIncrement : undefined,
		currency: data.currencyUnit.split('/')[0],
		...(data.isCustomGrade && {
			orderspecs: mapCustomSpecsObjectToArray(data.customSpecs).filter(
				spec => !!spec.spec_id && !isNil(spec.value)
			),
		}),
		tolerance: parseInt(data.tolerance) || undefined,
		shipment_type: data.cargoType,
		inspection: data.inspection,
		role: data.role,
		principal: data.role === Role.Principal ? Role.Principal : Role.Broker,
		environment: data.environment,
		hidden: data.nameVisibility === NameVisibility.Hidden,
		performance_bond_percent: data.performanceBond,
		terms_id: data.contractTerms,
		contract_number: data.contractNumber || undefined,
		file_contract_id: data.file_contract_id,
		recipients,
		comment_order: data.comment,
		file_ids: data.documents.map(file => file.replace(FILES_PREFIX, '')),
		validity_option: data.validity.time
			? mapTimeRangeOptionToApi(data.validity.time)
			: undefined,
		validity: data.validity.time
			? moment().utc().add(getTimeOptionMinutes(data.validity.time), 'minutes').toDate()
			: timezoneDateToIsoString(data.validity),
		validity_timezone: data.validity.tz,
		version: data.version,
	});
};

export const mapCustomSpecsObjectToArray = (customSpecs = {}) => {
	return Object.entries(customSpecs).map(item => {
		const key = item[0].split('-')[1];

		return { spec_id: key, value: item[1] ? parseFloat(item[1]) : undefined };
	});
};

const mapCustomSpecsArrayToObject = customSpecs => {
	return customSpecs
		? customSpecs.reduce((acc, item) => {
				return {
					[`spec-${item.spec_id}`]: item.value,
					...acc,
				};
		  }, {})
		: null;
};

export const getDefaultValuesFromApi = (response, recipients, userId, isEditing, isCopying) => {
	const editingParams = isEditing
		? {
				created_at: response.created_at,
				_key: response._key,
				recipients_list: response.recipients_list,
				version: response.version,
				isEditing,
				filesArray: response.files.map(file => ({
					file: {
						id: file._id,
						_key: file._key,
						name: file.name,
						type: file.mime,
						created_at: file.created_at,
					},
					fileId: file._key,
					isUploaded: true,
					key: file._key,
				})),
		  }
		: {
				isCopying,
		  };

	const isMyContract = response.file_contract?.updated_by === userId;

	return {
		...editingParams,
		market: response.market,
		// quote
		type: response.order_type,
		volume: response.volume,
		productAndType: [response.product_id, response.type_id].join('|'),
		inco: response.inco_id,
		port: response.primary_ports[0]._key,
		portNames: response.primary_ports.reduce((acc, port) => {
			acc[port._key] = `${port.name}, ${port.country_id}`;
			return acc;
		}, {}),
		date: {
			startDate: response.delivery_date_from,
			endDate: response.delivery_date_to,
			format: response.delivery_mode,
		},
		origin: response.origin_country_ids,
		grade: response.grade._key || response.grade_id,
		harvest: response.harvest_year,
		speciality: response.speciality,
		// specification
		isCustomGrade: response.grade.is_custom,
		customSpecs: mapCustomSpecsArrayToObject(response.orderspecs),
		// pricing
		orderType: response.is_indicative ? QuoteType.Indicative : QuoteType.Firm,
		priceType: response.has_price
			? response.price_type === PriceType.Flat
				? DrawerPriceType.Flat
				: response.futures_contract
			: DrawerPriceType.NoPrice,
		futuresMonth: new Date(response.futures_contract_date).valueOf(),
		price: response.price,
		currencyUnit: `${response.currency}/${response.price_unit}`,
		payment: response.payment,
		priceIncrementType: response.price_increment_type,
		priceIncrement: response.price_increment,
		priceIncrementBasisShipment: {
			startDate: response.price_increment_basis_shipment_from,
			endDate: response.price_increment_basis_shipment_to,
			format: response.price_increment_basis_shipment_mode,
		},
		// shipment
		tolerance: response.tolerance,
		cargoType: response.shipment_type,
		inspection: response.inspection,
		ports: response.primary_ports.map(port => ({
			name: port._key,
			volume: port.volume,
			terminal: port.terminal,
			rate: port.rate,
		})),
		// additional info
		environment: response.environment,
		nameVisibility: response.hidden ? NameVisibility.Hidden : NameVisibility.Visible,
		role: response.role,
		counterparties: recipients,
		performanceBond: response.performance_bond_percent,
		contractTerms: response.terms_id,
		contractNumber: response.contract_number || '',
		comment: response.comment_order,
		file_contract_id: isMyContract ? response.file_contract_id : undefined,
		file_contract: isMyContract ? response.file_contract : undefined,
		// validity
		validity: isEditing ? getDefaultDateTimeTzValue() : mapValidity(response),
		documents: [],
		documentNames: [],
		allDocumentsUploaded: true,
	};
};

export const mapPreviewDataToOrderView = (preview, formData, user) => {
	const gradespecs = formData.specs
		.map(({ _key, value, ...spec }) => ({
			_key,
			value: formData.isCustomGrade ? formData.customSpecs[`spec-${_key}`] : value,
			spec: { ...spec },
		}))
		.filter(({ value }) => value);

	const [mainPortName, mainPortCountryId] = formData.portNames[formData.ports[0].name].split(
		', '
	);

	const contractTermsName = formData.contractTermsOptions.find(
		option => option.value === formData.contractTerms
	)?.text;

	return {
		...preview,
		is_order_preview: true,
		_key: formData._key,
		version: formData.version,
		created_at: formData.created_at,
		product: formData.productObject,
		grade: { name: formData.gradeObject.text, gradespecs },
		price_unit: formData.currencyUnit.split('/').at(-1),
		currency: formData.currencyUnit.split('/').at(0),
		primary_ports: formData.ports.map(port => ({
			name: mainPortName,
			country_id: mainPortCountryId,
		})),
		ports_loading: preview.ports_loading.map(({ port_id, ...portData }, index) => {
			const [name, country_id] = formData.portNames[port_id].split(', ');
			return {
				_key: port_id,
				name,
				country_id,
				...portData,
			};
		}),
		ports_discharging: preview.ports_discharging.map(({ port_id, ...portData }, index) => {
			const [name, country_id] = formData.portNames[port_id].split(', ');
			return {
				_key: port_id,
				name,
				country_id,
				...portData,
			};
		}),
		inco: { name: preview.inco_id, _key: preview.inco_id },
		origin_countries: (formData.originObjects || []).map(origin => ({
			_key: origin.id,
			name: origin.text,
		})),
		principal: preview.role === Role.Principal ? Role.Principal : Role.Broker,
		user: {
			_key: user.session._key,
			avatar_color: user.avatar_color,
			name: user.session.name,
			first_name: user.session.first_name,
			last_name: user.session.last_name,
			company: {
				name: user.company_name,
				country_code: user.session.company_country_code,
			},
		},
		terms: { name: contractTermsName },
		recipients_list: formData.fullContactsList,
		file_contract: formData.file_contract,
		files: formData.documents
			.map(documentId => ({
				name: formData.documentNames.find(document => document.id === documentId)?.name,
				_key: documentId,
				_id: documentId,
			}))
			.filter(Boolean),
	};
};
